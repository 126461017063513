import React, { useState, useEffect, useCallback } from 'react';
import { goBack, push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DatePicker, Dropdown, Input, Modal, Table, TimePicker, Pagination } from 'antd';
import {
  CloseOutlined,
  DownOutlined,
  LeftCircleOutlined,
  LeftOutlined,
  RightCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { isEmpty, uniqBy } from 'lodash';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

// Local imports
import Button from 'components/Button';
import AdminPersonCard from '../../../components/AdminPersonCard';
import WeekSelector from 'components/WeekSelector';
import { api } from '../../../conf';
import { strings } from '../../../strings/StringsProvider';
import { getUsersAction } from '../../../actions/users.actions';
import { getOmamaReportStatisticsAction } from '../../../actions/report.actions';
import { deleteAction } from '../../../actions/admin.actions';
import { deepSum } from 'tools/utils';
import UserInfoPanel from './UserInfoPanel';
import ImageGalleryNav from 'components/ImageGalleryNav';
import GalleryFolders from 'components/GalleryFolders';
import ImageGallery from 'components/ImageGallery';
import { omamaGalleryFoldersMap } from 'data/enums';

import {
  getAdminClientsAction,
  getAdminOmamaClientsAction,
  getAdminOmamaActionsAction,
  changeNextWeek,
  changePreviousWeek,
  changeWeek,
  changeNextMonth,
  changePreviousMonth,
  getAdminUser,
  saveClient,
  adminOmamaChangeDate,
  getOmamasSupervisions,
} from '../../../actions/admin.actions';

import { removeActionPhotoAction } from '../../../actions/omama.actions';

import { setLoading } from '../../../actions/status.actions';

import './AdminOmama.scss';
import EditProfile from '../../../components/EditProfile';
import ActionDetailAdmin from '../../../components/ActionDetailAdmin';
import OmamaSupervision from 'containers/omama/OmamaSupervision';
import RentedAccessories from 'containers/omama/RentedAccessories';
import DayActions from '../../../components/DayActions';
import {
  showActionModal,
  showActiontModalEnd,
  showClientModal,
  showClientModalEnd,
} from '../../../actions/omama.actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const { RangePicker } = DatePicker;
const subMenuItems = [
  strings.clients,
  strings.actionOneUpperCase,
  strings.gallery,
  strings.info,
  strings.supervisions,
  strings.helperThings,
  strings.weeklyPlan,
];

const actionsColumns = [
  {
    title: strings.date,
    dataIndex: 'date',
    key: 'date',
    render: (date) => <span>{dayjs(date).format('D. M. YYYY')}</span>,
  },
  {
    title: strings.time,
    dataIndex: 'date',
    key: 'time',
    // width: '8vw',
    render: (date) => <span>{dayjs(date).format('HH:mm')}</span>,
  },
  {
    title: strings.clientsNameOrAction,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: strings.status,
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      let stat = status;
      switch (true) {
        case status.startsWith('canceled'):
          stat = strings.closedByClient;
          break;
        case status === 'active':
          stat = strings.active1;
          break;
        case status === 'done':
          stat = strings.done;
          break;
        default:
          break;
      }
      return <span>{stat}</span>;
    },
  },
];

function AdminOmama({ match }) {
  const [edit, setEdit] = useState(false);
  const [tab, setTab] = useState(decodeURIComponent(window.location.hash.substr(1)));
  const [visibleModalAdd, setVisibleModalAdd] = useState(false);
  const [typKey, setTypKey] = useState('client');
  const [typ, setTyp] = useState(strings.visitOfClient);
  const [actionDate, setActionDate] = useState('');
  const [actionTime, setActionTime] = useState(dayjs());
  const [client, setClient] = useState(strings.chooseClient);
  const [clientKey, setClientKey] = useState('');
  const [actionKey, setActionKey] = useState('');
  const [actionName, setActionName] = useState(strings.chooseAction);
  const [timeSpent, setTimeSpent] = useState(1);
  const [differentAction, setDifferentAction] = useState('');
  const [comment, setComment] = useState('');
  const [detailAction, setDetailAction] = useState(undefined);
  const [doneLessons, setDoneLessons] = useState([]);
  const [doneClubs, setDoneClubs] = useState([]);
  const [iconClicked, setIconClicked] = useState(!isEmpty(window.location.hash.substr(1)) ? true : false);
  const [openedFolder, setOpenedFolder] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { userId } = useParams();
  const userRole = jwtDecode(localStorage.getItem('access-token')).role;
  const history = useHistory();
  const pageSize = 9;

  const user = useSelector((state) => state.admin.oneUser);
  const clients = useSelector((state) => state.admin.omamaClientsById);
  const activeClients = useSelector((state) => state.admin.clientsByStatus.active || []);
  const inactiveClients = useSelector((state) => state.admin.clientsByStatus.inactive || []);
  const allActionsByDay = useSelector((state) => state.admin.allActionsByDay);
  const actionsById = useSelector((state) => state.admin.actionsById);
  const startWeek = useSelector((state) => state.admin.startWeek);
  const endWeek = useSelector((state) => state.admin.endWeek);
  const omamaStartMonth = useSelector((state) => state.admin.omamaStartMonth);
  const omamaEndMonth = useSelector((state) => state.admin.omamaEndMonth);
  const omamaFrom = useSelector((state) => state.admin.omamaFrom);
  const omamaTo = useSelector((state) => state.admin.omamaTo);
  const reportStatistics = useSelector((state) => state.report.reportStatistics);
  const supervisions = useSelector((state) => state.admin.omamaSupervisions);
  const actionID = useSelector((state) => state.omama.actionID);
  const clientModal = useSelector((state) => state.omama.clientModal);
  const actionModal = useSelector((state) => state.omama.actionModal);
  const selectAllActionTable = createSelector(
    [(state) => state.admin.allActions, (state) => state.admin.clients],
    (allActions, clients) => {
      const allClientsById = {};
      Object.values(clients).forEach((client) => {
        allClientsById[client._id] = client;
      });

      const allActionsTable = allActions.map((action) => ({
        ...action,
        name: allClientsById[action.id]
          ? `${allClientsById[action.id].firstName} ${allClientsById[action.id].lastName}`
          : typeof action.id === 'string'
            ? action.id
            : strings.unknowAction,
      }));

      return allActionsTable;
    },
  );
  const allActionsTable = useSelector(selectAllActionTable);

  const dispatch = useDispatch();

  const changePage = useCallback((location) => dispatch(push(location)), [dispatch]);

  const weekFormat = `${dayjs(startWeek).format('YYYY-MM-DD')}  ~  ${dayjs(endWeek).format('YYYY-MM-DD')}`;

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const handleClick = useCallback(() => {
    const currentTab = decodeURIComponent(window.location.hash.substr(1));
    if (iconClicked === true) {
      document.getElementsByTagName('body')[0].style.overflow = 'unset';
      if (currentTab && isMobile()) {
        dispatch(goBack());
      }
    } else if (iconClicked === false) {
      window.scrollTo(0, 0);
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
    setIconClicked((prevIconClicked) => !prevIconClicked);
  }, [dispatch, iconClicked]);

  const changeTab = useCallback(
    (tabArg) => {
      switch (tabArg) {
        case strings.statistics:
          break;
        case strings.clients:
          break;
        case strings.actionOneUpperCase:
          dispatch(setLoading(true));
          try {
            dispatch(
              getAdminOmamaActionsAction(
                userId,
                omamaStartMonth || dayjs().startOf('month').format('YYYY-MM-DD'),
                dayjs(omamaEndMonth).add(1, 'days').format('YYYY-MM-DD') ||
                  dayjs().endOf('month').add(1, 'days').format('YYYY-MM-DD'),
              ),
            );
            dispatch(setLoading(false));
          } catch (error) {
            dispatch(setLoading(false));
          }
          break;
        case strings.info:
          try {
            dispatch(setLoading(true));
            dispatch(getOmamaReportStatisticsAction(dayjs().year(), userId));
            dispatch(setLoading(false));
          } catch (error) {
            console.error('Error during calling getOmamaReportStatisticsAction', error);
            dispatch(setLoading(false));
          }
          break;
        case strings.supervisions:
          try {
            dispatch(setLoading(true));
            if (user._id && !isMobile()) {
              dispatch(getOmamasSupervisions(user._id));
            }
            dispatch(setLoading(false));
          } catch (error) {
            dispatch(setLoading(false));
          }
          break;
        case strings.weeklyPlan:
          try {
            dispatch(setLoading(true));
            dispatch(
              getAdminOmamaActionsAction(
                userId,
                startWeek || dayjs().startOf('week').format('YYYY-MM-DD'),
                dayjs(endWeek).endOf('week').add(1, 'days').format('YYYY-MM-DD'),
              ),
            );
            dispatch(setLoading(false));
          } catch (error) {
            dispatch(setLoading(false));
          }
          break;
        default:
          break;
      }

      setTab(tabArg);
      if (isMobile()) changePage(`#${tabArg}`);
      else window.history.replaceState(null, match.url, `#${tabArg}`);
    },
    [dispatch, changePage, endWeek, match.url, omamaEndMonth, omamaStartMonth, startWeek, user._id, userId],
  );

  useEffect(() => {
    if (!isMobile() && isEmpty(window.location.hash.substr(1))) {
      window.history.replaceState(null, match.url, `#${strings.clients}`);
      setTab(strings.clients);
    }
  }, [match.url]);

  useEffect(() => {
    async function getData() {
      try {
        dispatch(setLoading(true));
        const { data } = await axios.get(
          api.getAdminOmamaActions(
            userId,
            dayjs().startOf('year').format('YYYY-MM-DD'),
            dayjs().endOf('year').add(1, 'days').format('YYYY-MM-DD'),
          ),
        );
        const doneLessons = [];
        const doneClubs = [];

        data.data.forEach((action) => {
          if (action.client === true && action.status === 'done') {
            if (action.differentAction === 'club') {
              doneClubs.push(action);
            } else {
              doneLessons.push(action);
            }
          }
        });

        const uniqClubs = uniqBy(doneClubs, (club) => {
          return club.clubNames + club.date;
        });
        setDoneLessons(doneLessons);
        setDoneClubs(uniqClubs);
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
      }
    }

    dispatch(adminOmamaChangeDate([null, null]));
    dispatch(getUsersAction());
    dispatch(getAdminOmamaClientsAction(userId));
    dispatch(getAdminClientsAction());

    getData();
  }, [dispatch, userId]);

  useEffect(() => {
    if (isEmpty(user) || user._id !== userId) {
      dispatch(getAdminUser(userId));
    }
  }, [user, userId, dispatch]);

  const nextWeek = () => {
    dispatch(changeNextWeek(userId, startWeek, endWeek));
  };

  const previousWeek = () => {
    dispatch(changePreviousWeek(userId, startWeek, endWeek));
  };

  const onWeekChange = (date) => {
    if (date) dispatch(changeWeek(userId, date?.format()));
  };

  const nextMonth = () => {
    dispatch(changeNextMonth(userId, omamaStartMonth, omamaEndMonth));
  };

  const previousMonth = () => {
    dispatch(changePreviousMonth(userId, omamaStartMonth, omamaEndMonth));
  };

  const changeActionDate = (date) => {
    dispatch(adminOmamaChangeDate(date));
  };

  const changeActionDateMobile = (date) => {
    dispatch(adminOmamaChangeDate(date));
    const from = date?.[0];
    const to = date?.[1];
    (from || to) && dispatch(getAdminOmamaActionsAction(userId, from, dayjs(to).add(1, 'days')));
  };

  const searchActions = () => {
    dispatch(getAdminOmamaActionsAction(userId, omamaFrom, dayjs(omamaTo).add(1, 'days')));
  };

  const deleteActionFromActions = async (id) => {
    dispatch(
      deleteAction(id, true, () => {
        detailActionCancel();
        dispatch(getUsersAction());
      }),
    ).then(() => {
      dispatch(
        getAdminOmamaActionsAction(
          userId,
          omamaStartMonth || dayjs().startOf('month').format('YYYY-MM-DD'),
          dayjs(omamaEndMonth).add(1, 'days').format('YYYY-MM-DD') ||
            dayjs().endOf('month').add(1, 'days').format('YYYY-MM-DD'),
        ),
      );
    });
  };

  const deleteActionFromWeeklyPlan = async (id) => {
    dispatch(showClientModalEnd());
    dispatch(showActiontModalEnd());
    dispatch(
      deleteAction(id, true, () => {
        detailActionCancel();
        dispatch(getUsersAction());
      }),
    ).then(() => {
      dispatch(getAdminOmamaActionsAction(userId, startWeek, dayjs(endWeek).add(1, 'days')));
    });
  };

  const mobileChangeTab = useCallback(
    (newTab) => {
      changeTab(newTab);
      if (iconClicked) setIconClicked(false);
      handleClick();
    },
    [changeTab, handleClick, iconClicked],
  );

  const renderStatsSubmenu = () => {
    return (
      <div className="admin-omama-submenu just-omama">
        <Button
          type="secondary"
          icon={<img src="/images/Icons/go-back.svg" alt="back-button" />}
          label={strings.goBack}
          className="go-back-button"
          onClick={() => dispatch(goBack())}
        />
        <ul>
          {subMenuItems.map((item, i) => (
            <li
              key={i}
              className={classNames({ active: tab === item })}
              onClick={() => changeTab(item)}
              data-test-id={`adminOmama-subMenu-${item}`}
            >
              <span className="submenu-item">{item}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderStatsSubmenuMobile = () => {
    return subMenuItems.map((item, i) => (
      <div key={i} className="admin-omama-tabsMobile_row" onClick={() => mobileChangeTab(item)}>
        <span>{item}</span>
        <RightOutlined />
      </div>
    ));
  };

  const renderStatsKlienti = () => {
    return (
      <div>
        <div className="adminOmamaList activeHeader" data-test-id="adminOmama-clients-active">
          {strings.activeUpper}
        </div>
        <div className="adminOmamaList active" data-test-id="adminOmama-clients-activeClients">
          {activeClients.map((client) => (
            <AdminPersonCard
              key={client._id}
              name={client.firstName + ' ' + client.lastName}
              birthDate={client.birthDate}
              id={client._id}
              type={'user'}
              photo={client._id}
              changePage={() => {
                dispatch(saveClient(client));
                changePage('/admin/klienti/' + client._id);
              }}
            />
          ))}
        </div>
        <div className="adminOmamaList inactiveHeader" data-test-id="adminOmama-clients-inactive">
          {strings.nonActiveUpper}
        </div>
        <div className="adminOmamaList inactive" data-test-id="adminOmama-clients-inactiveClients">
          {/* Neaktívni */}
          {inactiveClients.map((client) => (
            <AdminPersonCard
              key={client._id}
              name={client.firstName + ' ' + client.lastName}
              birthDate={client.birthDate}
              id={client._id}
              type={'user'}
              photo={client._id}
              changePage={() => {
                dispatch(saveClient(client));
                changePage('/admin/klienti/' + client._id);
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderStatsKlientiMobile = () => {
    return (
      <div className="adminOmamaClientList">
        <div className="adminOmamaList activeHeader">{strings.activeUpper}</div>
        <div className="adminOmamaList active">
          {activeClients.map((client) => (
            <AdminPersonCard
              key={client._id}
              name={client.firstName + ' ' + client.lastName}
              birthDate={client.birthDate}
              id={client._id}
              type={'user'}
              photo={client._id}
              changePage={() => {
                dispatch(saveClient(client));
                changePage('/admin/klienti/' + client._id);
              }}
            />
          ))}
        </div>
        <div className="adminOmamaList inactiveHeader">{strings.nonActiveUpper}</div>
        <div className="adminOmamaList inactive">
          {/* Neaktívni */}
          {inactiveClients.map((client) => (
            <AdminPersonCard
              key={client._id}
              name={client.firstName + ' ' + client.lastName}
              birthDate={client.birthDate}
              id={client._id}
              type={'user'}
              photo={client._id}
              changePage={() => {
                dispatch(saveClient(client));
                changePage('/admin/klienti/' + client._id);
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  // MODALOVSKE veci
  const findLabel = (key, options) => {
    for (const option of options) {
      if (option.key === key) return option.label;
      if (option.children) {
        const childLabel = findLabel(key, option.children);
        if (childLabel) return childLabel;
      }
    }
    return '';
  };

  const onTypeChange = ({ key }) => {
    setTypKey(key);
    setTyp(typOptions.find((option) => option.key === key).label);
  };

  const onClientChange = ({ key }) => {
    setClientKey(key);
    setClient(klientsOptions.find((option) => option.key === key).label);
  };

  const onActionChange = ({ key }) => {
    setActionKey(key);
    setActionName(findLabel(key, actionOptions));
  };

  const typOptions = [
    {
      key: 'client',
      label: (
        <span data-test-id="admin-omamaDetail-addAction-typeselect-dropdown-option-visit">{strings.visitOfClient}</span>
      ),
    },
    {
      key: 'akcia',
      label: <span data-test-id="admin-omamaDetail-addAction-typeselect-dropdown-option-action">{strings.action}</span>,
    },
  ];

  const klientsOptions = Object.values(clients).map((client) => ({
    key: client._id,
    label: (
      <span data-test-id={`data-test-id="admin-omamaDetail-addAction-client-input-option-${client._id}`}>
        {client.firstName} {client.lastName}
      </span>
    ),
  }));

  const omamaLevel = user.level;

  const actionOptions = [
    {
      key: 'work',
      type: 'group',
      label: strings.work,
      children: [
        omamaLevel >= 3
          ? {
              key: 'mentoringSenior',
              label: <span data-test-id="admin-omama-action-type-mentoringSenior">{strings.mentorTillSenior}</span>,
            }
          : {
              key: 'mentoring',
              label: <span data-test-id="admin-omama-action-type-mentoring">{strings.mentoring}</span>,
            },
        {
          key: 'telefonat',
          label: <span data-test-id="admin-omama-action-type-telefonat">{strings.phoneCall}</span>,
        },
        {
          key: 'parentalClub',
          label: <span data-test-id="admin-omama-action-type-parentalClub">{strings.parentalClub1}</span>,
        },
        {
          key: 'supervision',
          label: <span data-test-id="admin-omama-action-type-supervision">{strings.supervision}</span>,
        },
        { key: 'training', label: <span data-test-id="admin-omama-action-type-training">{strings.training}</span> },
        { key: 'interNDA', label: <span data-test-id="admin-omama-action-type-interNDA">{strings.interNDA}</span> },
        { key: 'otherJob', label: <span data-test-id="admin-omama-action-type-otherJob">{strings.otherJob}</span> },
        { key: 'education', label: <span data-test-id="admin-omama-action-type-education">{strings.education}</span> },
      ],
    },
    {
      key: 'notBeingInJob',
      type: 'group',
      label: strings.notBeingInJob,
      children: [
        { key: 'vacation', label: <span data-test-id="admin-omama-action-type-vacation">{strings.vacation}</span> },
        { key: 'doctor', label: <span data-test-id="admin-omama-action-type-doctor">{strings.doctor}</span> },
        {
          key: 'familyDoctor',
          label: <span data-test-id="admin-omama-action-type-familyDoctor">{strings.familyDoctor}</span>,
        },
        {
          key: 'workUnable',
          label: <span data-test-id="admin-omama-action-type-workUnable">{strings.workUnable}</span>,
        },
        { key: 'OCR', label: <span data-test-id="admin-omama-action-type-OCR">{strings.OCR}</span> },
        {
          key: 'extraTimeOff',
          label: <span data-test-id="admin-omama-action-type-extraTimeOff">{strings.extraTimeOff}</span>,
        },
        { key: 'other', label: <span data-test-id="admin-omama-action-type-other">{strings.other}</span> },
      ],
    },
  ];

  const showModalAdd = () => {
    setVisibleModalAdd(true);
  };

  const handleCancelModalAdd = () => {
    setVisibleModalAdd(false);
    setTypKey('client');
    setTyp(strings.visitOfClient);
    setActionDate('');
    setActionTime(dayjs());
    setClient(strings.chooseClient);
    setClientKey('');
    setActionKey('');
    setActionName(strings.chooseAction);
    setTimeSpent(1);
    setDifferentAction('');
    setComment('');
  };

  const checkButton = () => {
    if (typKey === 'client') {
      if (!actionDate || !actionTime || !clientKey) {
        return true;
      }
    } else if (typKey === strings.action.toLowerCase()) {
      if (!actionDate || !actionTime || !actionName || actionName === strings.chooseAction || !timeSpent) {
        return true;
      }
    }
    return false;
  };

  const addAction = async () => {
    dispatch(setLoading(true));

    const time = dayjs(actionTime).format('HH:mm');
    const date = dayjs(actionDate + 'T' + time).format();

    const body = {
      date,
      client: typKey === 'client',
      id: typKey === 'client' ? clientKey : actionName,
      differentAction,
      omamaId: user._id,
      status: 'active',
      comment,
      timeSpent: typKey === 'client' ? 1.2 : parseFloat(timeSpent),
    };

    const from = omamaFrom || omamaStartMonth || dayjs().startOf('month').format('YYYY-MM-DD');
    const to =
      omamaTo ||
      dayjs(omamaEndMonth).add(1, 'days').format('YYYY-MM-DD') ||
      dayjs().endOf('month').add(1, 'days').format('YYYY-MM-DD');
    try {
      await axios.post(api.adminAddNewAction, body);
      if (dayjs(actionDate).isBetween(from, to, undefined, '[)')) {
        dispatch(getAdminOmamaActionsAction(userId, from, to));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    } finally {
      // load updated data from server
      dispatch(getAdminOmamaActionsAction(userId, omamaStartMonth, dayjs(omamaEndMonth).add(1, 'days')));
      setVisibleModalAdd(false);
      setActionDate('');
      setActionTime(dayjs());
      handleCancelModalAdd();
    }
  };

  const showDetailAction = (action) => {
    setDetailAction(action);
  };

  const detailActionOk = () => {
    setDetailAction(undefined);
  };

  const detailActionCancel = () => {
    setDetailAction(undefined);
  };

  const getStatusName = (status) => {
    let stat = status;
    switch (true) {
      case status.startsWith('canceled'):
        stat = strings.closedByClient;
        break;
      case status === 'active':
        stat = strings.active1;
        break;
      case status === 'done':
        stat = strings.done;
        break;
      default:
        break;
    }
    return stat;
  };

  const renderStatsAkcie = () => {
    return (
      <div>
        <div className="adminClient-form">
          <div className="adminClient-form-group">
            <RangePicker
              onChange={changeActionDate}
              name="omamaCourse"
              defaultValue={[null, null]}
              value={[omamaFrom, omamaTo]}
              className="adminClient_datepicker"
              placeholder={['Od', 'Do']}
            />
            <Button
              type="secondary"
              onClick={searchActions}
              disabled={!omamaFrom || !omamaTo}
              data-test-id="adminOmama-submenu-search"
            >
              {strings.search}
            </Button>
            <div className="admin-omama-actions-week__changeWeek">
              <div style={{ fontWeight: 'bold' }}>
                <LeftCircleOutlined
                  className="week-navigation-left big"
                  onClick={previousMonth}
                  data-test-id="adminOmama-actions-previousMonth-button"
                />
              </div>
              <div className="monthData" style={{ padding: '0 5px', fontWeight: 'bold' }}>
                {dayjs(omamaEndMonth).format('MMMM YYYY')}
              </div>
              <div style={{ fontWeight: 'bold' }}>
                <RightCircleOutlined
                  className="week-navigation-right big"
                  onClick={nextMonth}
                  data-test-id="adminOmama-actions-nextMonth-button"
                />
              </div>
            </div>
          </div>
          <div className="adminClient-form-add-button" data-test-id="adminOmama-submenu-addAction">
            <Button
              style={{ marginLeft: '15px' }}
              className="adminClient-button"
              onClick={showModalAdd}
              data-test-id="adminOmama-submenu-addAction-button"
            >
              {strings.addAction}
            </Button>
          </div>
        </div>
        <Modal
          title={strings.addAction}
          open={visibleModalAdd}
          onCancel={handleCancelModalAdd}
          cancelText={strings.close}
          onOk={addAction}
          okText={strings.confirm}
          okButtonProps={{ disabled: checkButton() }}
        >
          <div className="pridatContainer">
            <div className="typeDropdown" data-test-id="admin-omamaDetail-addAction-typeselect-dropdown">
              <Dropdown
                trigger={['click']}
                className="actionsDropdown"
                menu={{ items: typOptions, onClick: onTypeChange }}
                placement="bottom"
              >
                <div>
                  {typ} <DownOutlined style={{ fontSize: '20px' }} />
                </div>
              </Dropdown>
            </div>
            <br />
            <div className="addNewActionPicker">
              <h3 className="pickerHeader">{strings.date}</h3>
              <Input
                type="date"
                value={actionDate}
                onChange={(event) => setActionDate(event.target.value)}
                className="picker"
                data-test-id="admin-omamaDetail-addAction-date-input"
              />
            </div>
            <div className="addNewActionPicker">
              <h3 className="pickerHeader">{strings.time}</h3>
              <br />
              <TimePicker
                defaultValue={dayjs('12:00', 'HH:mm')}
                type="time"
                format={'HH:mm'}
                minuteStep={15}
                needConfirm={false}
                onChange={(value) => setActionTime(value)}
                value={actionTime}
                className="picker"
                placeholder={strings.chooseTime}
              />
            </div>
            <div className="adminOmama-spentTimePicker">
              <div>{typKey === strings.action.toLowerCase() && strings.spentTime}</div>
              <br />
              {typKey === strings.action.toLowerCase() && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Input
                    type="number"
                    id="timeSpent"
                    onChange={(event) => setTimeSpent(event.target.value)}
                    value={timeSpent}
                    step={0.5}
                    min={0}
                  />
                  <div>h</div>
                </div>
              )}
            </div>
            <br />
            {typKey === strings.action.toLowerCase() ? (
              <div>
                <p className="actionHeader">{strings.chooseAction1}</p>
                <div className="dropdownSelect">
                  <Dropdown
                    trigger={['click']}
                    menu={{ items: actionOptions, onClick: onActionChange }}
                    placement="bottom"
                  >
                    <div data-test-id="admin-omamaDetail-addAction-selectAction-dropdown">
                      {actionName} <DownOutlined />
                    </div>
                  </Dropdown>
                </div>
                {actionKey && (
                  <div className="activityFormField">
                    <div>
                      <Input
                        placeholder={strings.comment}
                        value={comment}
                        onChange={(event) => setComment(event.target.value)}
                        data-test-id="admin-omamaDetail-addAction-comment-input"
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="dropdownSelect">
                <Dropdown trigger={['click']} menu={{ items: klientsOptions, onClick: onClientChange }}>
                  <div data-test-id="admin-omamaDetail-addAction-client-input">
                    {client} <DownOutlined />
                  </div>
                </Dropdown>
              </div>
            )}
            <br />
            <br />
          </div>
        </Modal>

        <div>
          <Table
            className="new-table omama-actions-table"
            rowKey="_id"
            dataSource={allActionsTable}
            columns={actionsColumns}
            rowClassName={(record) =>
              record.status === 'canceled - ' + strings.lessonCanceledClient ? 'grayColor' : 'inherit'
            }
            onRow={(record) => ({
              onClick: () => showDetailAction(record),
            })}
          />
        </div>
        <Modal
          centered
          title={strings.detail}
          open={detailAction !== undefined}
          onCancel={detailActionCancel}
          onOk={detailActionOk}
          footer={null}
        >
          {detailAction && (
            <ActionDetailAdmin
              action={detailAction}
              deleteAction={deleteActionFromActions}
              timeRange={[omamaStartMonth, omamaEndMonth]}
            />
          )}
        </Modal>
      </div>
    );
  };

  const renderStatsAkcieMobile = () => {
    return (
      <div className="actionsOmamaMobileView">
        <div className="actionsOmamaMobileView-container">
          <div className="actionsOmamaMobileView-container-inner">
            <div className="actionsOmamaMobileView-container-inner-options">
              <div className="actionsOmamaMobileView-container-inner-options-rangePicker">
                <RangePicker
                  onChange={changeActionDateMobile}
                  name="omamaCourse"
                  defaultValue={[null, null]}
                  value={[omamaFrom, omamaTo]}
                  placeholder={['Od', 'Do']}
                />
              </div>
              <div className="actionsOmamaMobileView-container-inner-options-monthPicker">
                <div
                  className="actionsOmamaMobileView-container-inner-options-monthPicker-icon"
                  data-test-id="admin-adminOmama-previousMonth-button-mobile"
                >
                  <LeftOutlined onClick={previousMonth} />
                </div>
                <div className="actionsOmamaMobileView-container-inner-options-monthPicker-text">
                  {dayjs(omamaEndMonth).format('MMMM YYYY')}
                </div>
                <div
                  className="actionsOmamaMobileView-container-inner-options-monthPicker-icon"
                  data-test-id="admin-adminOmama-nextMonth-button-mobile"
                >
                  <RightOutlined onClick={nextMonth} />
                </div>
              </div>
              <div
                className="actionsOmamaMobileView-container-inner-options-addAction"
                onClick={showModalAdd}
                data-test-id="admin-adminOmama-addAction-button-mobile"
              >
                <img className="ikona pridatButton" src="/images/Icons/plus_icon.png" alt="ikona" />
              </div>
              <Modal
                title={strings.addAction}
                open={visibleModalAdd}
                onCancel={handleCancelModalAdd}
                cancelText={strings.close}
                cancelButtonProps={{ 'data-test-id': 'adminOmamaDetail-actions-addAction-modal-cancel' }}
                onOk={addAction}
                okText={strings.confirm}
                okButtonProps={{
                  disabled: checkButton(),
                  'data-test-id': 'adminOmamaDetail-actions-addAction-modal-confirm',
                }}
                data-test-id="adminOmamaDetail-actions-addAction-modal"
              >
                <div className="pridatContainer">
                  <div className="typeDropdown" data-test-id="admin-omamaDetail-addAction-typeselect-dropdown-mobile">
                    <Dropdown
                      trigger={['click']}
                      className="actionsDropdown"
                      menu={{ items: typOptions, onClick: onTypeChange }}
                      placement="bottom"
                      data-test-id="admin-omamaDetail-addAction-typeselect-dropdown-select-mobile"
                    >
                      <div>
                        {typ} <DownOutlined style={{ fontSize: '20px' }} />
                      </div>
                    </Dropdown>
                  </div>
                  <br />
                  <div className="addNewActionPicker">
                    <h3 className="pickerHeader">{strings.date}</h3>
                    <Input
                      type="date"
                      value={actionDate}
                      onChange={(event) => setActionDate(event.target.value)}
                      className="picker"
                      data-test-id="admin-omamaDetail-addAction-date-input-mobile"
                    />
                  </div>
                  <div className="addNewActionPicker">
                    <h3 className="pickerHeader">{strings.time}</h3>
                    <br />
                    <TimePicker
                      defaultValue={dayjs('12:00', 'HH:mm')}
                      type="time"
                      format={'HH:mm'}
                      minuteStep={15}
                      needConfirm={false}
                      onChange={(value) => setActionTime(value)}
                      value={actionTime}
                      className="picker"
                      placeholder={strings.chooseTime}
                      data-test-id="admin-omamaDetail-addAction-time-input-mobile"
                    />
                  </div>
                  <div className="adminOmama-spentTimePicker">
                    <div>{typKey === strings.action.toLowerCase() && strings.spentTime1}</div>
                    <br />
                    {typKey === strings.action.toLowerCase() && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input
                          type="number"
                          id="timeSpent"
                          onChange={(event) => setTimeSpent(event.target.value)}
                          value={timeSpent}
                          step={0.5}
                          min={0}
                          data-test-id="admin-omamaDetail-addAction-timeSpend-input-mobile"
                        />
                        <div>h</div>
                      </div>
                    )}
                  </div>
                  <br />
                  {typKey === strings.action.toLowerCase() ? (
                    <div>
                      <p className="actionHeader">{strings.chooseAction1}</p>
                      <div className="dropdownSelect">
                        <Dropdown
                          trigger={['click']}
                          menu={{ items: actionOptions, onClick: onActionChange }}
                          placement="bottom"
                        >
                          <div data-test-id="admin-omamaDetail-addAction-selectAction-dropdown-mobile">
                            {actionName} <DownOutlined />
                          </div>
                        </Dropdown>
                      </div>
                      {actionKey && (
                        <div className="activityFormField">
                          <div>
                            <Input
                              placeholder={strings.comment}
                              value={comment}
                              onChange={(event) => setComment(event.target.value)}
                              data-test-id="admin-omamaDetail-addAction-comment-input-mobile"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="dropdownSelect">
                      <Dropdown trigger={['click']} menu={{ items: klientsOptions, onClick: onClientChange }}>
                        <div data-test-id="admin-omamaDetail-addAction-client-input-mobile">
                          {client} <DownOutlined />
                        </div>
                      </Dropdown>
                    </div>
                  )}
                  <br />
                  <br />
                </div>
              </Modal>
            </div>
            <div className="actionsOmamaMobileView-container-inner-toDoList">
              {allActionsTable
                .sort((a, b) => dayjs(b.date).diff(a.date))
                .map((action) => {
                  return (
                    <div
                      key={action._id}
                      className="actionsOmamaMobileView-container-inner-toDoList-toDo"
                      onClick={() => showDetailAction(action)}
                    >
                      <div className="actionsOmamaMobileView-container-inner-toDoList-toDo-upperCont">
                        <div className="actionsOmamaMobileView-container-inner-toDoList-toDo-upperCont-title">
                          {action.name}
                        </div>
                      </div>
                      <div className="actionsOmamaMobileView-container-inner-toDoList-toDo-lowerCont">
                        <div className="actionsOmamaMobileView-container-inner-toDoList-toDo-lowerCont-date">
                          {dayjs(action.date).format('D. M. YYYY')}
                        </div>
                        <div className="actionsOmamaMobileView-container-inner-toDoList-toDo-lowerCont-time">
                          {dayjs(action.date).format('HH:mm')}
                        </div>
                        <div
                          style={{
                            color: action.status === 'canceled - ' + strings.lessonCanceledClient ? 'gray' : '#41aea6',
                          }}
                          className="actionsOmamaMobileView-container-inner-toDoList-toDo-lowerCont-status"
                        >
                          {getStatusName(action.status)}
                        </div>
                        <div className="actionsOmamaMobileView-container-inner-toDoList-toDo-lowerCont-arrow">
                          <RightOutlined className="icon" />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <Modal
              centered
              styles={{ body: { padding: '24px 16px' } }}
              title={strings.detail}
              open={detailAction !== undefined}
              onCancel={detailActionCancel}
              onOk={detailActionOk}
              footer={null}
            >
              {detailAction && (
                <ActionDetailAdmin
                  action={detailAction}
                  deleteAction={deleteActionFromActions}
                  timeRange={[omamaStartMonth, omamaEndMonth]}
                />
              )}
            </Modal>
          </div>
        </div>
      </div>
    );
  };

  const showFolder = (folder) => {
    setOpenedFolder(folder);
  };

  const goBackInNavigation = useCallback(() => {
    showFolder(null);
    setPageNumber(1);
  }, []);

  const galleryPageImages = useCallback(
    (gallery) => {
      const reversedGallery = [...gallery].reverse();
      return reversedGallery.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    },
    [pageNumber, pageSize],
  );

  const changeGalleryPage = useCallback((page) => {
    setPageNumber(page);
  }, []);

  const removeGalleryImage = useCallback(
    (photoID) => {
      dispatch(removeActionPhotoAction(userId, photoID, '', openedFolder)).then(() => {
        if (user.gallery?.[openedFolder]?.length === 1) {
          goBackInNavigation();
        }
        dispatch(getAdminUser(userId));
      });
    },
    [dispatch, userId, openedFolder, goBackInNavigation, user.gallery],
  );

  const renderGallery = () => {
    const folders = user.gallery;
    return (
      <div className="mobileGallery">
        <div className="goBack backFromGallery" onClick={history.goBack}>
          <LeftOutlined />
          <span>{strings.back1}</span>
        </div>
        <div className="adminGalleryWrapper">
          {!user.gallery || user.gallery?.length === 0 ? (
            <p>{strings.galleryIsEmpty}</p>
          ) : (
            <>
              <ImageGalleryNav goBack={goBackInNavigation} folder={omamaGalleryFoldersMap[openedFolder]} />
              {!openedFolder ? (
                <GalleryFolders folders={folders} clickHandler={(key) => showFolder(key)} omamaFolders={true} />
              ) : (
                <ImageGallery gallery={galleryPageImages(folders[openedFolder])} removeImage={removeGalleryImage} />
              )}
              {openedFolder && folders[openedFolder]?.length !== 0 && (
                <Pagination
                  className="new-pagination"
                  current={pageNumber}
                  defaultPageSize={9}
                  total={folders[openedFolder]?.length}
                  onChange={changeGalleryPage}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderStatsInfo = () => {
    // Pracovny vykaz
    const {
      nevyplneneVykazy,
      doneLessons,
      doneClubs,
      canceledActions,
      mentoring,
      mentoringSenior,
      phoneCall,
      parentalClub,
      supervision,
      training,
      INTER_NDA,
      otherJob,
      education,
      vacation,
      doctorVisit,
      doctorRelative,
      workUnable,
      OCR,
      extraTimeOff,
      other,
    } = reportStatistics;
    const omamaLevel = user.level;
    return (
      <div>
        <div className="omamaReportAdmin-main">
          <br />
          <h4 data-test-id="adminOmama-incompleteReports">
            <b>
              {strings.noFilledReports}: <span style={{ color: '#41aea6' }}>{nevyplneneVykazy}</span>
            </b>
          </h4>
          <br />
          <h4 data-test-id="adminOmama-doneLessonsThisYear">
            <b>
              {strings.doneLessons}: <span style={{ color: '#41aea6' }}>{doneLessons}</span>
            </b>
          </h4>
          <h4 data-test-id="adminOmama-doneClubsThisYear">
            <b>
              {strings.doneClubs}: <span style={{ color: '#41aea6' }}>{doneClubs}</span>
            </b>
          </h4>
          <h4 data-test-id="adminOmama-canceledActionsThisYear">
            <b>
              {strings.canceledActions}: <span style={{ color: '#41aea6' }}>{canceledActions}</span>
            </b>
          </h4>
          <br />
          <h4 data-test-id="adminOmama-otherWorks">
            <b>{strings.otherWorkingActions}</b>
          </h4>
          <div data-test-id="adminOmama-mentorings">
            {strings.mentoring}:{' '}
            <span style={{ color: '#41aea6' }}>
              {mentoring} {strings.hour}
            </span>
          </div>
          {omamaLevel >= 3 && (
            <div data-test-id="adminOmama-mentoringTillSenior">
              {strings.mentorTillSenior}:{' '}
              <span style={{ color: '#41aea6' }}>
                {mentoringSenior} {strings.hour}
              </span>
            </div>
          )}
          <div data-test-id="adminOmama-telefonat">
            {strings.phoneCalls}:{' '}
            <span style={{ color: '#41aea6' }}>
              {phoneCall} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminOmama-parentalClubs">
            {strings.parentalClub}:{' '}
            <span style={{ color: '#41aea6' }}>
              {parentalClub} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminOmama-supervisions">
            {strings.supervision}:{' '}
            <span style={{ color: '#41aea6' }}>
              {supervision} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminOmama-trainings">
            {strings.training}:{' '}
            <span style={{ color: '#41aea6' }}>
              {training} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminOmama-interNDA">
            {strings.interNDA}:{' '}
            <span style={{ color: '#41aea6' }}>
              {INTER_NDA} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminOmama-otherJobs">
            {strings.otherJob}:{' '}
            <span style={{ color: '#41aea6' }}>
              {otherJob} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminOmama-educations">
            {strings.education}:{' '}
            <span style={{ color: '#41aea6' }}>
              {education} {strings.hour}
            </span>
          </div>
          <br />
          <h4 data-test-id="adminOmama-absences">
            <b>{strings.notInWork}</b>
          </h4>
          <div data-test-id="adminOmama-vacations">
            {strings.vacation}:{' '}
            <span style={{ color: '#41aea6' }}>
              {vacation} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminOmama-doctors">
            {strings.doctor}:{' '}
            <span style={{ color: '#41aea6' }}>
              {doctorVisit} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminOmama-familyDoctors">
            {strings.familyDoctor}:{' '}
            <span style={{ color: '#41aea6' }}>
              {doctorRelative} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminOmama-workIncapacity">
            {strings.workUnable}:{' '}
            <span style={{ color: '#41aea6' }}>
              {workUnable} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminOmama-OCR">
            {strings.OCR}:{' '}
            <span style={{ color: '#41aea6' }}>
              {OCR} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminOmama-extraTimeOff">
            {strings.extraTimeOff}:{' '}
            <span style={{ color: '#41aea6' }}>
              {extraTimeOff} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminOmama-others">
            {strings.other}:{' '}
            <span style={{ color: '#41aea6' }}>
              {other} {strings.hour}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const showDetailSupervision = (action) => {
    changePage(`/supervisor/form/${action._id}`);
  };

  const renderSupervisions = () => {
    const supervisionsColumns = [
      {
        title: strings.date,
        dataIndex: 'date',
        key: 'date',
        render: (date) => <span>{dayjs(date).format('D. M. YYYY')}</span>,
      },
      {
        title: strings.time,
        dataIndex: 'date',
        key: 'time',
        // Show time of supervision shifted by +1 hour for omamas
        render: (date) => <span>{dayjs(date).add(1, 'hours').format('HH:mm')}</span>,
      },
      {
        title: strings.supervision1,
        dataIndex: 'supervisorName',
        key: 'supervisorName',
      },
      {
        title: strings.comment,
        dataIndex: 'comment',
        key: 'comment',
      },
      {
        title: strings.amountOfPoints,
        dataIndex: 'pointsSum',
        key: 'pointsSum',
        render: (text, record) => {
          let outputText = text;
          if (record.questions) {
            const sum = deepSum(record.questions);
            outputText = `${sum.value}/${sum.max}, ${Math.round((sum.value / (sum.max / 100)) * 100) / 100}%`;
          }
          return <span>{outputText}</span>;
        },
      },
    ];

    const sortedSupervisions = supervisions.slice();
    sortedSupervisions.sort((a, b) => {
      return dayjs(b.date).diff(a.date);
    });

    return (
      <div>
        <Table
          className="new-table omama-supervisions-table"
          rowKey="_id"
          dataSource={sortedSupervisions}
          columns={supervisionsColumns}
          onRow={(record) => ({
            onClick: () => showDetailSupervision(record),
          })}
        />
      </div>
    );
  };

  const renderAccessories = () => (
    <div className="omama-profile">
      <RentedAccessories userId={userId} />
    </div>
  );

  const countLekcie = () => {
    let pocetLekcii = 0;
    allActionsByDay.forEach((dayLessons) => {
      dayLessons.Actions.forEach((lesson) => {
        if (lesson.client === true) pocetLekcii++;
      });
    });
    switch (true) {
      case pocetLekcii === 1:
        return { count: pocetLekcii, label: strings.oneLesson };
      case pocetLekcii === 0:
        return { count: pocetLekcii, label: strings.moreThenFiveLessons };
      case pocetLekcii >= 5:
        return { count: pocetLekcii, label: strings.moreThenFiveLessons };
      case pocetLekcii >= 2 || pocetLekcii <= 4:
        return { count: pocetLekcii, label: strings.twoTillFourLessons };
      default:
        return { count: pocetLekcii, label: strings.twoTillFourLessons };
    }
  };

  const countSkolenia = () => {
    let pocetSkoleni = 0;
    allActionsByDay.forEach((dayNonClientActions) => {
      dayNonClientActions.Actions.forEach((action) => {
        if (action.client === false) pocetSkoleni++;
      });
    });
    switch (true) {
      case pocetSkoleni === 1:
        return { count: pocetSkoleni, label: strings.actionOne };
      case pocetSkoleni === 0 || pocetSkoleni >= 5:
        return { count: pocetSkoleni, label: strings.actionOne };
      case pocetSkoleni >= 2 || pocetSkoleni <= 4:
        return { count: pocetSkoleni, label: strings.actionOne };
      default:
        return { count: pocetSkoleni, label: strings.actionOne };
    }
  };

  const openClientModal = () => {
    return actionID !== '' && actionsById[actionID] && clientModal;
  };

  const openActionModal = () => {
    if (actionID !== '') {
      return actionModal;
    } else {
      return false;
    }
  };

  const closeModal = () => {
    dispatch(showClientModalEnd());
    dispatch(showActiontModalEnd());
  };

  const showClientModalWindow = (actionID, closeID, type) => {
    dispatch(showClientModal(actionID, closeID, type));
  };

  const showActionModalWindow = (actionID) => {
    dispatch(showActionModal(actionID));
  };

  const renderWeeklyPlan = () => {
    return (
      <div className="plan-main">
        <div className="plan-topPanel">
          <div className="user_activities">
            <div className="lekcie">
              <div className="count">{countLekcie().count}</div>
              <div>{countLekcie().label}</div>
            </div>
            <div className="skolenia">
              <div className="count">{countSkolenia().count}</div>
              <div>{countSkolenia().label}</div>
            </div>
          </div>
        </div>
        <div className="weekPicker">
          <div className="omamaWeek">{strings.week}</div>
          <div className="week-selector-container">
            <LeftCircleOutlined
              className="week-navigation-left big "
              onClick={previousWeek}
              data-test-id="adminOmama-weekplan-previousMonth-button"
            />
            <WeekSelector value={startWeek} onChangeHandler={onWeekChange} format={weekFormat} />
            <RightCircleOutlined
              className="week-navigation-right big"
              onClick={nextWeek}
              data-test-id="adminOmama-weekplan-nextMonth-button"
            />
          </div>
        </div>
        <div className="actionsContainer">
          {allActionsByDay.map((action) => {
            return (
              <div key={action.day} className="actionContainer">
                <DayActions
                  key={action.day}
                  day={action.day}
                  clients={clients}
                  actions={action.Actions}
                  showClientModal={showClientModalWindow}
                  showActionModal={showActionModalWindow}
                />
              </div>
            );
          })}
          {(openClientModal() || openActionModal()) && (
            <Modal
              title={strings.detail}
              open={openClientModal() || openActionModal()}
              onCancel={closeModal}
              footer={false}
            >
              <ActionDetailAdmin
                action={actionsById[actionID]}
                deleteAction={() => deleteActionFromWeeklyPlan(actionID)}
              />
            </Modal>
          )}
        </div>
      </div>
    );
  };

  const fetchUserAfterUpdate = () => {
    dispatch(getAdminUser(userId));
  };

  const switchEdit = (flag) => {
    setEdit(flag);
  };

  if (user._id && user._id === userId) {
    return (
      <>
        {edit && <EditProfile role={user.role} bckButton={switchEdit} fetchUser={fetchUserAfterUpdate} />}
        {!edit && (
          <div className="admin-userDetail">
            <UserInfoPanel
              user={user}
              userRole={userRole}
              switchEdit={switchEdit}
              doneLessons={doneLessons}
              doneClubs={doneClubs}
            />

            <div className="admin-rightside doNotShowOnMobile">
              <div>{renderStatsSubmenu()}</div>
              <div
                className={`admin-omama-bottompanel ${
                  tab === strings.info || tab === strings.weeklyPlan ? 'white-background' : ''
                }`}
                data-test-id="adminOmama-bottomPanel-desktop"
              >
                {tab === strings.clients && renderStatsKlienti()}
                {tab === strings.actionOneUpperCase && renderStatsAkcie()}
                {tab === strings.gallery && renderGallery()}
                {tab === strings.info && renderStatsInfo()}
                {tab === strings.supervisions && renderSupervisions()}
                {tab === strings.helperThings && renderAccessories()}
                {tab === strings.weeklyPlan && renderWeeklyPlan()}
              </div>
            </div>
            <div className="admin-omama-tabsMobile showJustOnMobile" data-test-id="adminOmama-statsSubmenu-mobile">
              {renderStatsSubmenuMobile()}
            </div>
            <div
              className="tab_modal showJustOnMobile"
              style={{ display: iconClicked ? 'block' : 'none' }}
              data-test-id="adminOmama-modal-mobile"
            >
              <div className="tab_modal_menu showJustOnMobile" onClick={handleClick}>
                {iconClicked ? <CloseOutlined className="tab_modal_menu-closeIcon closeIcon" /> : ''}
              </div>
              <div className="admin-omama-bottompanel showJustOnMobile" data-test-id="adminOmama-bottomPanel-mobile">
                {tab === strings.clients && renderStatsKlientiMobile()}
                {tab === strings.actionOneUpperCase && renderStatsAkcieMobile()}
                {tab === strings.gallery && renderGallery()}
                {tab === strings.info && renderStatsInfo()}
                {tab === strings.supervisions && <OmamaSupervision omamaID={user._id} />}
                {tab === strings.helperThings && renderAccessories()}
                {tab === strings.weeklyPlan && renderWeeklyPlan()}
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else return null;
}

AdminOmama.propTypes = {
  match: PropTypes.object,
};

export default AdminOmama;
