import React, { useState } from 'react';
import './ImageGallery.scss';
import PropTypes from 'prop-types';
import Base64Image from '../components/Base64Image';
import { Modal, Popconfirm } from 'antd';
import Button from './Button';
import { strings } from '../strings/StringsProvider';

const ImageGallery = ({ gallery, removeImage, clientID }) => {
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState(null);

  const showModal = image => {
    setImage(image);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const confirmRemoveImage = () => {
    if (clientID) {
      removeImage(clientID, image);
    } else {
      removeImage(image);
    }

    handleCancel();
  };

  return (
    <div className="imageGalleryMain">
      {!clientID && gallery.length === 0 && <p>{strings.galleryIsEmpty}</p>}
      {gallery.map((image) => (
        <div key={image} onClick={() => showModal(image)}>
          <Base64Image
            className="imageWrapper"
            type={`gallery-${clientID ? 'client' : 'omama'}`}
            imageID={image + '_thumbNail'}
            showDate={true}
          />
        </div>
      ))}
      <Modal className="modalGallery" open={visible} onCancel={handleCancel} footer={null} closable={true}>
        <div id="modalGallery">
          <Base64Image
            key={image}
            type={`gallery-${clientID ? 'client' : 'omama'}`}
            imageID={image + '_photo'}
            showDate={true}
          />
          <Popconfirm
            title={strings.deletePhotoQuestion}
            okText={strings.yes}
            cancelText={strings.no}
            okType="default"
            onConfirm={confirmRemoveImage}
          >
            <Button type="primary">{strings.deletePicture}</Button>
          </Popconfirm>
        </div>
      </Modal>
    </div>
  );
};

ImageGallery.propTypes = {
  gallery: PropTypes.arrayOf(PropTypes.string),
  removeImage: PropTypes.func,
  clientID: PropTypes.string
};

export default ImageGallery;
