import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Descriptions } from 'antd';

import { strings } from '../../../strings/StringsProvider';

import './AdminClient.scss';

export const AdminClientTabInfoDesktop = ({ descriptionColumns, client, calculateAttendanceTime }) => {
  // const client = client;
  return (
    <div className="clientInfo">
      <Descriptions title="Dieťa" bordered column={descriptionColumns} size="small" className="adminClient-description">
        <Descriptions.Item label={<span>{strings.street}</span>}>{client.street}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.birthDate}</span>}>
          {dayjs(client.birthDate).format('D. M. YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.birthCity}</span>}>{client.birthDateAdress}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.breastFeedTime}</span>}>{client.breastFeedTime}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.birthWeight2}</span>}>{client.birthWeight}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.birthLength1}</span>}>{client.birthLength}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.birthHeadCircumference}</span>}>
          {client.birthHeadCircumference}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.visiting}</span>}>
          {client.visitingInstitution ? client.institutionName : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.breastFed1}</span>}>
          {client.breastFed ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.solidFood}</span>}>{client.solidFood}</Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={strings.gdpr}
        bordered
        column={descriptionColumns}
        size="small"
        className="adminClient-description"
      >
        <Descriptions.Item label={<span>{strings.photosInApp}</span>}>
          {client.photosInApp ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.photosOnWeb}</span>}>
          {client.photosOnWeb ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.photosPrinted}</span>}>
          {client.photosPrinted ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.videoReports}</span>}>
          {client.videoReports ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.pediatricScreenings}</span>}>
          {client.pediatricScreenings ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.growthTracking}</span>}>
          {client.growthTracking ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.educationCarrierGrowth}</span>}>
          {client.educationCarrierGrowth ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.contactLater}</span>}>
          {client.contactLater ? strings.yes : strings.no}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Matka" bordered column={descriptionColumns} size="small" className="adminClient-description">
        <Descriptions.Item label={<span>{strings.nameAndSurname}</span>}>{client.motherName}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.birthDate}</span>}>
          {client.motherBirthDate && dayjs(client.motherBirthDate).format('D. M. YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.healthState}</span>}>{client.motherHealthState}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.phoneNumber}</span>}>{client.motherPhone}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.job}</span>}>{client.motherWork}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.educationEnded}</span>}>{client.motherEducation}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.classEnded}</span>}>{client.motherEducationLevel}</Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={strings.father}
        bordered
        column={descriptionColumns}
        size="small"
        className="adminClient-description"
      >
        <Descriptions.Item label={<span>{strings.nameAndSurname}</span>}>{client.fatherName}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.birthDate}</span>}>
          {client.fatherBirthDate && dayjs(client.fatherBirthDate).format('D. M. YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.healthState}</span>}>{client.fatherHealthState}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.phoneNumber}</span>}>{client.fatherPhone}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.job}</span>}>{client.fatherWork}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.educationEnded}</span>}>{client.fatherEducation}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.classEnded}</span>}>{client.fatherEducationLevel}</Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={strings.siblings}
        bordered
        column={descriptionColumns}
        size="small"
        className="adminClient-description"
      >
        <Descriptions.Item label={<span>{strings.numberOfSiblings}</span>}>{client.siblingsNumber}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.childBirthOrder1}</span>}>{client.childBirthOrder}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.homeLanguage}</span>}>{client.language}</Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={strings.pregnancy}
        bordered
        column={descriptionColumns}
        size="small"
        className="adminClient-description"
      >
        <Descriptions.Item label={<span>{strings.smokingPregnancy}</span>}>
          {client.smokingPregnancy ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.alcoholPregnancy}</span>}>
          {client.alcoholPregnancy ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.smokingBreastFeeding}</span>}>
          {client.smokingBreastFeeding ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.alcoholBreastFeeding}</span>}>
          {client.alcoholBreastFeeding ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.drugsPregnancy}</span>}>
          {client.drugsPregnancy ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.medicinePregrancy}</span>}>
          {client.medicinePregrancy}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.drugsBreastFeeding}</span>}>
          {client.drugsBreastFeeding ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.medicineBreastFeeding}</span>}>
          {client.medicineBreastFeeding}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.story}</span>}>{client.pregnancy}</Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={strings.birth}
        bordered
        column={descriptionColumns}
        size="small"
        className="adminClient-description"
      >
        <Descriptions.Item label={<span>{strings.birthInTerm}</span>}>
          {client.birthInTerm ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.prematureBirth}</span>}>
          {client.prematureBirth ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.lateBirth}</span>}>
          {client.lateBirth ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.inducedBirth}</span>}>
          {client.inducedBirth ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.spontaneousBirth}</span>}>
          {client.spontaneousBirth ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.csectionBirth}</span>}>
          {client.csectionBirth ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.naturalBirth}</span>}>
          {client.naturalBirth ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.childbirthComplications}</span>}>
          {client.childbirthComplications}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title="Prvé dva roky"
        bordered
        column={descriptionColumns}
        size="small"
        className="adminClient-description"
      >
        <Descriptions.Item label={<span>{strings.smokingNearChildBelowTwoYears}</span>}>
          {client.smokingNearChildBelowTwoYears ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.alcoholNearChildBelowTwoYears}</span>}>
          {client.alcoholNearChildBelowTwoYears ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.drugsNearChildBelowTwoYears}</span>}>
          {client.drugsNearChildBelowTwoYears ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.medicineNearChildBelowTwoYears}</span>}>
          {client.medicineNearChildBelowTwoYears}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={strings.health}
        bordered
        column={descriptionColumns}
        size="small"
        className="adminClient-description"
      >
        <Descriptions.Item label={<span>{strings.healthIssues}</span>}>{client.healthIssues}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.obligatoryVaccination}</span>}>
          {client.obligatoryVaccination ? strings.yes : strings.no}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={strings.worriesAbout}
        bordered
        column={descriptionColumns}
        size="small"
        className="adminClient-description"
      >
        <Descriptions.Item label={<span>{strings.eyesightIssueConcern}</span>}>
          {client.eyesightIssueConcern ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.hearingIssueConcern}</span>}>
          {client.hearingIssueConcern ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.sleepIssueConcern}</span>}>
          {client.sleepIssueConcern ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.growNurtureIssueConcern}</span>}>
          {client.growNurtureIssueConcern ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.healthIssueConcern}</span>}>
          {client.healthIssueConcern ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.wholeGrowIssueConcern}</span>}>
          {client.wholeGrowIssueConcern ? strings.yes : strings.no}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={strings.living}
        bordered
        column={descriptionColumns}
        size="small"
        className="adminClient-description"
      >
        <Descriptions.Item
          label={
            <>
              <span>{strings.houseMaterial}</span>
              <br />
              <span>{strings.materials}</span>
            </>
          }
        >
          {client.houseMaterial}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.houseRooms}</span>}>{client.houseRooms}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.houseInhabitants}</span>}>{client.houseInhabitants}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.houseSameRoomForChildAndKitchen} </span>}>
          {client.houseSameRoomForChildAndKitchen ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.houseFuel}</span>}>{client.houseFuel}</Descriptions.Item>
        <Descriptions.Item label={<span>{strings.houseSeparateBathRoom} </span>}>
          {client.houseSeparateBathRoom ? strings.yes : strings.no}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={strings.kindergarden}
        bordered
        column={descriptionColumns}
        size="small"
        className="adminClient-description"
      >
        <Descriptions.Item label={<span>{strings.childAtemptKindergarden}</span>}>
          {client.visitingInstitution ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.nameOfKindergarden}</span>}>
          {client.institutionName}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.lengthAttendedSchool}</span>}>
          {client.infantSchoolStartDate &&
            calculateAttendanceTime(client.infantSchoolStartDate, client.infantSchoolEndDate)}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title="Základná škola"
        bordered
        column={descriptionColumns}
        size="small"
        className="adminClient-description"
      >
        <Descriptions.Item label={<span>{strings.childAtemptPrimarySchool}</span>}>
          {client.elementarySchool ? strings.yes : strings.no}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.namePrimarySchool}</span>}>
          {client.elementarySchoolName}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.typePrimarySchool}</span>}>
          {client.specialSchool ? strings.specialSchool : client.elementarySchool ? strings.ordinarySchool : ''}
        </Descriptions.Item>
        <Descriptions.Item label={<span>{strings.lengthAttendedSchool}</span>}>
          {client.elementarySchoolStartDate &&
            calculateAttendanceTime(client.elementarySchoolStartDate, client.elementarySchoolEndDate)}
        </Descriptions.Item>
      </Descriptions>
      <h2>
        {strings.psychomotoricsGrouwth}: {strings.rollingOver}
      </h2>
      <b>{client.growthRollingover}</b>
      <h2>
        {strings.psychomotoricsGrouwth}: {strings.sitting}
      </h2>
      <b>{client.growthSitting}</b>
      <h2>
        {strings.psychomotoricsGrouwth}: {strings.goingOnFour}
      </h2>
      <b>{client.growthCrawling}</b>
      <h2>
        {strings.psychomotoricsGrouwth}: {strings.walking}
      </h2>
      <b>{client.growthWalking}</b>
      <h2>
        {strings.psychomotoricsGrouwth}: {strings.chattering}
      </h2>
      <b>{client.growthCooing}</b>
      <h2>
        {strings.psychomotoricsGrouwth}: {strings.firstWords}
      </h2>
      <b>{client.growthTalking}</b>
      <h2>{strings.hospitalization2}</h2>
      <b>{client.hospitalization}</b>
      <h2>{strings.injuries1}</h2>
      <b>{client.injury}</b>
      <h2>{strings.regularVisits}</h2>
      <b>
        {client.regularExaminations
          ? (client.examinationOffice || strings.yes) + ' - ' + dayjs(client.dateOfLastExamination).format('D. M. YYYY')
          : strings.no}
      </b>
      <h2>{strings.healthCondition1}</h2>
      <b>{client.healthCondition}</b>
    </div>
  );
};

AdminClientTabInfoDesktop.propTypes = {
  descriptionColumns: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  calculateAttendanceTime: PropTypes.func.isRequired,
};
