import React from 'react';
import PropTypes from 'prop-types';

//Local imports
import './Button.scss';

function Button({ className, type, onClick, disabled, style, icon, label, children, 'data-test-id': dataTestId }) {
  return (
    <button
      className={`button ${type} ${className}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={style}
      data-test-id={dataTestId}
    >
      {icon}
      {label || children}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  icon: PropTypes.object,
  'data-test-id': PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  className: 'primary-button',
  type: 'primary',
  onClick: () => undefined,
  disabled: false,
  style: {},
  icon: null,
  label: '',
  children: null,
};

export default Button;
