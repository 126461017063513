import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getOmamaTokenAction, resetOmamaAction } from '../../actions/omama.actions';
import { api } from '../../conf/api';
import { strings } from '../../strings/StringsProvider';
import { Upload, Button, Alert, Slider } from 'antd';
import { LeftOutlined, UploadOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import AvatarEditor from 'react-avatar-editor';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useImageUrlContext } from 'context/ImageUrlContext';
import { jwtDecode } from 'jwt-decode';

function ChangeProfilePicture() {
  const [fileList, setFileList] = useState([]);
  const [scale, setScale] = useState(1);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const editorRef = useRef(null);
  const omama = useSelector((state) => state.omama.omama);

  const { username } = jwtDecode(localStorage.getItem('access-token'));
  const { removeImageUrl } = useImageUrlContext();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!omama.username) {
      dispatch(getOmamaTokenAction());
    }
  }, [dispatch, omama]);

  const uploadProps = () => {
    return {
      beforeUpload: (file) => {
        setFileList([file]);
        setAlertType('');
        setAlertMessage('');
        return false;
      },
      fileList,
    };
  };

  const getClientPhoto = () => {
    if (editorRef.current) {
      return editorRef.current.getImageScaledToCanvas().toDataURL();
    }
    return '';
  };

  const submitPicture = async () => {
    const photo = getClientPhoto();
    const reqBody = { photo };

    try {
      const { data } = await axios.post(api.changeProfilePicture, reqBody);
      if (data.success) {
        dispatch(resetOmamaAction());
        dispatch(getOmamaTokenAction());
        const imageFullId = `user/${username}`;
        removeImageUrl(imageFullId);
        history.goBack();
      }
    } catch (error) {
      setAlertType('error');
      setAlertMessage(strings.errorWhileChangingProfilePicture);
    }
  };

  const path = '/' + location.pathname.split('/', 2)[1];

  return (
    <div
      className={classNames('settings-container', {
        admin: path === '/admin',
      })}
    >
      <div className="settings-nav" tabIndex={0} onClick={history.goBack}>
        <LeftOutlined />
        <h3>{strings.changeProfilePicture}</h3>
      </div>
      <div className="settings-main">
        <Upload className="settings-button settings-upload" showUploadList={false} {...uploadProps()}>
          <UploadOutlined /> {strings.choosePicture}
        </Upload>
        {fileList[0] && (
          <div className="settings-avatar">
            <AvatarEditor
              ref={editorRef}
              image={fileList[0]}
              width={200}
              height={200}
              border={30}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={scale}
              rotate={0}
            />
            <div className="icon-wrapper">
              <ZoomOutOutlined />
              <Slider min={1} max={10} step={0.1} value={scale} onChange={(newScale) => setScale(newScale)} />
              <ZoomInOutlined />
            </div>
          </div>
        )}
        <Button
          className="settings-button"
          type="primary"
          style={{ marginTop: 16 }}
          shape="round"
          onClick={submitPicture}
          disabled={!fileList[0]}
        >
          {strings.changePhoto}
        </Button>
        {alertType && (
          <Alert type={alertType} message={alertMessage} style={{ marginTop: '10px', marginBottom: '10px' }} />
        )}
      </div>
    </div>
  );
}

export default ChangeProfilePicture;
