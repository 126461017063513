import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Pagination } from 'antd';

import GalleryFolders from '../../../components/GalleryFolders';
import ImageGallery from '../../../components/ImageGallery';
import ImageGalleryNav from '../../../components/ImageGalleryNav';

import { api } from '../../../conf';
import { strings } from '../../../strings/StringsProvider';

import { removePhotoAdminAction } from '../../../actions/admin.actions';

import { LeftOutlined } from '@ant-design/icons';

import './AdminClient.scss';

export const AdminClientTabGalleryDesktop = ({ client, match }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [folders, setFolders] = useState(null);
  const [openedFolder, setOpenedFolder] = useState(null);

  const dispatch = useDispatch();

  const makeFolders = useCallback(async () => {
    if (client) {
      if (!client?.gallery || client?.gallery?.length === 0) {
        return;
      }

      const galleryFolders = await axios.get(api.getGalleryFolders(client._id));
      setFolders(galleryFolders?.data?.data ? galleryFolders.data.data : {});
    }
  }, [client]);

  useEffect(() => {
    if (client && folders === null && location.pathname === match.url + '/galeria') {
      makeFolders();
    }
  }, [client, dispatch, folders, makeFolders, match.url]);

  const pageCount = (page, pageSize) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };

  const imageCount = (gallery) => {
    return gallery.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  const removeImage = async (clientID, photoID) => {
    await dispatch(removePhotoAdminAction(clientID, photoID));

    // If deleting the last photo in the folder, close folder
    // and return one level back
    const folder = folders[openedFolder];
    if (folder && folder?.length === 1 && folder[0] === photoID) {
      setOpenedFolder(null);
    }

    makeFolders();
  };

  const showFolder = (folder) => {
    setOpenedFolder(folder);
  };

  const goBack = () => {
    showFolder(null);
    // Reset page when navigating away
    setPageNumber(1);
  };

  return (
    window.location.pathname === match.url + '/galeria' && (
      <div className="mobileGallery">
        {client.gallery?.length === 0 && (
          <div className="goBack backFromGallery" onClick={() => history.goBack()}>
            <LeftOutlined />
            <span>{strings.back1}</span>
          </div>
        )}
        <div className="adminGalleryWrapper">
          {client.gallery?.length === 0 ? (
            <p>{strings.galleryIsEmpty}</p>
          ) : (
            <>
              <ImageGalleryNav goBack={() => goBack()} goToClient={() => history.goBack()} folder={openedFolder} />
              {!openedFolder ? (
                <GalleryFolders folders={folders} clickHandler={(key) => showFolder(key)} />
              ) : (
                <ImageGallery
                  gallery={imageCount(folders[openedFolder] || [])}
                  removeImage={removeImage}
                  clientID={client._id}
                />
              )}
              {openedFolder && (
                <Pagination
                  className="new-pagination"
                  defaultCurrent={1}
                  defaultPageSize={9}
                  total={folders[openedFolder]?.length}
                  onChange={pageCount}
                />
              )}
            </>
          )}
        </div>
      </div>
    )
  );
};

AdminClientTabGalleryDesktop.propTypes = {
  client: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
