/* eslint-disable no-debugger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode';
import { push } from 'connected-react-router';
import { Modal } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { getUserNotifications, closePopup } from 'slices/notifications';

// Local Imports
import { strings } from '../strings/StringsProvider';
import NotificationsListPopup from 'components/Notifications/NotificationsListPopup.js';
import './Header.scss';
import Base64Image from './Base64Image';

const headerName = [
  { url: '/omama/plan', name: strings.weeklyPlan },
  { url: '/omama/report', name: strings.workReport },
  { url: '/omama/supervizie', name: strings.supervisions },
  { url: '/omama/vyvinoveStadia', name: strings.developmentStages },
  { url: '/omama/klienti', name: strings.clients },
  { url: '/omama/galeria', name: strings.gallery },
  { url: '/omama/pridat', name: strings.add },
  { url: '/omama/aktivity', name: strings.activities },
  { url: '/omama/screening/', name: strings.screening1 },
  { url: '/omama/clientProfiles/', name: strings.clientsProfile },
  { url: '/omama/clientGallery/', name: strings.clientsGallery },
  { url: '/omama/omamaGallery/', name: strings.actionGallery },
  { url: '/omama/pomocky', name: strings.borrowedTools },
  { url: '/omama/nastavenia', name: strings.settings },
  { url: '/omama/notifikacie', name: strings.notification },
  { url: '/omama/zrkadlo', name: strings.mirror },
];

const NotificationBadge = () => {
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  useEffect(() => {
    if (notifications.status === 'initial') {
      dispatch(getUserNotifications());
    }
  }, [dispatch, notifications.status]);

  const showNotifications = () => {
    dispatch(push('/omama/notifikacie'));
  };

  return (
    <div className="notification" onClick={showNotifications}>
      <img alt="notificationIcon" className="notification-icon" src="/images/Icons/notification_icon.png" />
      {notifications.data.new?.length > 0 && <div className="notification-badge">{notifications.data.new?.length}</div>}
    </div>
  );
};

const Header = (props) => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const getHeader = (pathTitle) => headerName.find((item) => pathTitle.startsWith(item.url)).name;
  const [title, setTitle] = useState(getHeader(props.location));

  useEffect(() => {
    const title = getHeader(props.location);
    setTitle(title);
  }, [props.location]);

  const token = localStorage.getItem('access-token');
  const user = jwtDecode(token);

  return (
    <>
      <div className="flex-container">
        <div onClick={() => props.onOmamaPictureClick()}>
          <Base64Image
            className="logoheader"
            type="user"
            backUpImage="/images/cestavonLOGO_unofficial.png"
            imageID={user.username}
          />
        </div>
        <h1 className="header-headline">{title}</h1>
        <div className="header-rightSide">
          <NotificationBadge />
          <div className="menu" onClick={() => props.onClickMenu(true)}>
            <img className="burger" src="/images/Icons/burger.png" alt="" />
          </div>
        </div>
      </div>
      <Modal
        title={strings.notification}
        open={notifications.popup}
        footer={false}
        onCancel={() => dispatch(closePopup())}
      >
        <NotificationsListPopup />
      </Modal>
    </>
  );
};

//PropTypes
Header.propTypes = {
  onClickMenu: PropTypes.func,
  location: PropTypes.string,
  onOmamaPictureClick: PropTypes.func,
};

export default Header;
