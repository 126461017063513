import React, { forwardRef } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import './FormField.scss';

const FormField = forwardRef(function FormField({ placeholder, type = 'text', ...rest }) {
  return (
    <div>
      {type !== 'password' ? (
        <Input className="inputField" placeholder={placeholder} type={type} {...rest} />
      ) : (
        <Input.Password className="inputField" placeholder={placeholder} {...rest} />
      )}
    </div>
  );
});

FormField.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default FormField;
