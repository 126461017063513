import React from 'react';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import './WeekSelector.scss';

function WeekSelector({ value, onChangeHandler, format }) {
  return (
    <DatePicker
      picker="week"
      className="plan-week-picker"
      value={dayjs(value)}
      onChange={onChangeHandler}
      format={{ format }}
    />
  );
}

WeekSelector.propTypes = {
  value: PropTypes.string,
  onChangeHandler: PropTypes.func,
  format: PropTypes.string,
};

export default WeekSelector;
