import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// Create the context
const AppConfigContext = createContext(undefined);

export const useAppConfig = () => {
  const context = useContext(AppConfigContext);
  if (!context) {
    throw new Error('useAppConfig must be used within an AppConfigProvider');
  }
  return context;
};

export const AppConfigProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const isMobile = screenWidth < 768; // breakpoint for mobile

  // Update screen width on window resize
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(() => ({ screenWidth, isMobile }), [screenWidth, isMobile]);
  return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>;
};

AppConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
