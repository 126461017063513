import { cloneDeep, set, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button, Table, Checkbox } from 'antd';
import { CheckCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { jwtDecode } from 'jwt-decode';
import { getDateDiff } from 'tools/date.tools';

import './SupervisionForm.scss';
import { strings } from '../../strings/StringsProvider';
import {
  updateSupervisionFormAction,
  resetSupervisionForm,
  populateSupervisionFormAction,
  saveSupervisionFormAction,
} from '../../actions/supervisionForm.actions';
import { getAdminActivitiesAction } from '../../actions/admin.actions.js';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const requiredFields = [];
function SupervisionForm() {
  const user = jwtDecode(localStorage.getItem('access-token'));
  const { supervisionId } = useParams();
  const history = useHistory();

  const supervisionFormData = useSelector((state) => state.supervisionForm.data);
  const formDefinition = useSelector((state) => state.supervisionForm.form);
  const activities = useSelector((state) => state.admin.activities);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminActivitiesAction());
    if (!supervisionId) {
      dispatch(resetSupervisionForm());
    } else {
      dispatch(populateSupervisionFormAction(supervisionId));
    }

    return () => {
      dispatch(resetSupervisionForm());
    };
  }, [dispatch, supervisionId]);

  const fieldChangeHandler = (name) => (event) => {
    dispatch(updateSupervisionFormAction(name, event.target.value));
  };

  const checkRequiredFields = () => requiredFields.some((field) => !supervisionFormData[field]);

  const saveSupervision = async () => {
    const body = {
      questions: supervisionFormData.questions,
      targetToNextSupervision: supervisionFormData.targetToNextSupervision,
      textEvaluation: supervisionFormData.textEvaluation,
      status: 'done',
    };
    dispatch(saveSupervisionFormAction(supervisionId, body)).then(() => history.goBack());
  };

  const updateQuestionData = (path, value) => {
    const updatedQuestions = cloneDeep(supervisionFormData.questions);
    set(updatedQuestions, path, value);

    dispatch(updateSupervisionFormAction('questions', updatedQuestions));
  };

  const getPointsSum = (questions) => {
    const sumObj = formDefinition?.columns
      ? formDefinition?.columns.reduce((acc, column) => ({ ...acc, [column.value]: 0 }), {})
      : {};

    if (!questions) return 0;
    let max = 0;

    Object.values(questions).forEach((group) => {
      Object.values(group).forEach((question) => {
        Object.keys(question).forEach((col) => {
          sumObj[col] += Number(question[col]);
          max++;
        });
      });
    });

    return { tableSummary: sumObj, max };
  };

  const renderColumn = (record, col, summary, canEdit) => {
    // If it's summary row, display points
    if (record.key === 'summary') {
      return <div>{summary[col]}</div>;
    }

    // Group rows display nothing in cells
    if (record.children) return null;

    // Color cells if they don't contain a checkbox
    if (!record.enabledCols || record.enabledCols.indexOf(col) === -1) return null;

    // Display checkbox
    const isChecked =
      !isEmpty(supervisionFormData.questions) && supervisionFormData.questions[record.group][record.key][col];
    return canEdit ? (
      <Checkbox
        checked={isChecked}
        onChange={(val) => updateQuestionData(`${record.group}.${record.key}.${col}`, val.target.checked ? 1 : 0)}
      ></Checkbox>
    ) : isChecked ? (
      <CheckCircleOutlined style={{ fontSize: 20 }} />
    ) : (
      ''
    );
  };

  const onCell = (record, col) => {
    // Color cells if they don't contain a checkbox
    if (!record.enabledCols || record.enabledCols.indexOf(col) === -1)
      return {
        style: { borderLeft: '1px solid #41aea6' },
      };

    return {
      style: { background: '#ccf1ee', borderLeft: '1px solid #41aea6' },
    };
  };

  const getSupervisionActivities = (checkedActivities, allActivities, headers) => {
    const activities = checkedActivities
      .map((activity) => {
        return allActivities.find((a) => a._id === activity);
      })
      .filter((activity) => activity);

    return headers.map((header) => ({
      ...header,
      activity: activities.find((activity) => Number(activity.code.split('.')[1]) === header.code),
    }));
  };

  const getChildAge = (birthDate) => {
    const { months, weeks } = getDateDiff(birthDate, supervisionFormData.date);

    let childWeek = months + 'm/' + weeks + 't';
    return childWeek;
  };

  const getLectionNumber = (birthDate) => {
    const { months, weeks } = getDateDiff(birthDate, supervisionFormData.date);
    // Formula to get lection number, i.e.
    // 0m/1t -> none
    // 0m/2t -> none
    // 0m/3t -> 1
    // 0m/4t -> 2
    // 1m/1t -> 3
    // 1m/2t -> 4
    let weeksTotal = months * 4 + weeks - 2;
    return weeksTotal;
  };

  // Calculate total points for each column
  const { tableSummary, max } = getPointsSum(supervisionFormData.questions);
  const summaryTotal = Object.values(tableSummary).reduce((acc, val) => (acc += val), 0);

  const canEdit = user.role === 'supervisor' && user.username === supervisionFormData.supervisorID;

  const tableColumns = [
    {
      title: '',
      dataIndex: 'text',
      key: 'text',
      render: (text, record) => {
        if (record.key === 'summary') {
          return (
            <div>{`${text} (Spolu ${summaryTotal}/${max}, ${
              Math.round((summaryTotal / (max / 100)) * 100) / 100
            }%)`}</div>
          );
        }
        return text;
      },
    },
  ];

  formDefinition?.columns &&
    tableColumns.push(
      ...formDefinition.columns.map((column) => ({
        title: column.name,
        dataIndex: column.value,
        key: column.value,
        render: (_text, record) => renderColumn(record, column.value, tableSummary, canEdit),
        onCell: (record) => onCell(record, column.value),
      })),
    );

  const { omama, supervisor, client } = supervisionFormData;

  const headers = getSupervisionActivities(
    supervisionFormData.checkedActivities,
    activities,
    formDefinition?.headers || [],
  );

  return (
    <div className="supervision-form-background">
      <div className="supervision-form-wrapper">
        <h3 style={{ cursor: 'pointer' }} onClick={history.goBack} data-test-id="supervision-detail-form-backButton">
          <div className="back-button">
            <LeftOutlined /> {strings.back1}
          </div>
        </h3>
        <h2>{strings.supervision}</h2>
        {omama && supervisor && client ? (
          <>
            <div className="supervision-form-grid col-3">
              <div className="form-item">
                <p className="supervision-input-label">{strings.omama}</p>
                <p className="supervision-read-only">{omama.name}</p>
              </div>
              <div className="form-item">
                <p className="supervision-input-label">{strings.omamaCommunity}</p>
                <p className="supervision-read-only">{omama.city || '-'}</p>
              </div>
              <div className="form-item">
                <p className="supervision-input-label">{strings.supervisiroka}</p>
                <p className="supervision-read-only">{supervisor.name}</p>
              </div>
            </div>
            <div className="supervision-form-grid col-3">
              <div className="form-item">
                <div className="supervision-input-label">{strings.date}</div>
                <p className="supervision-read-only">{dayjs(supervisionFormData.date).format('D. M. YYYY')}</p>
              </div>
              <div className="form-item">
                <p className="supervision-input-label">{strings.omamaLessons}</p>
                <p className="supervision-read-only">{supervisionFormData.lessonsCount}</p>
              </div>
              <div className="form-item">
                <p className="supervision-input-label">{strings.omamaOrder}</p>
                <p className="supervision-read-only">{supervisionFormData.supervisionIndex + 1}.</p>
              </div>
            </div>
            <div className="supervision-form-grid">
              <div className="form-item">
                <div className="supervision-input-label">{strings.omamaGoal}</div>
                <textarea readOnly value={supervisionFormData.lastSupervisionTarget} />
              </div>
            </div>
            <div className="supervision-form-grid col-3">
              <div className="form-item">
                <p className="supervision-input-label">{strings.child}</p>
                <p className="supervision-read-only">{`${client.firstName} ${client.lastName}`}</p>
              </div>
              <div className="form-item">
                <div className="supervision-input-label">{strings.age}</div>
                <p className="supervision-read-only">{getChildAge(client.birthDate)}</p>
              </div>
              <div className="form-item">
                <div className="supervision-input-label">{strings.lessonNumber}</div>
                <p className="supervision-read-only">{getLectionNumber(client.birthDate)}</p>
              </div>
            </div>
            <div className="supervision-form-grid col-3">
              {headers.map((header) => (
                <div className="form-item" key={header.code}>
                  <div className="supervision-input-label">{header.name}</div>
                  <p className="supervision-read-only">{header.activity?.name}</p>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div>{strings.lessonNotFound}</div>
        )}
        <h2>{strings.lessonScore}</h2>
        <h3>{formDefinition.displayName || ''}</h3>
        <div>
          {formDefinition.table && (
            <Table
              size="small"
              className="table"
              rowKey={(record) => `${record.group || 'row'}-${record.key || 'group'}`}
              pagination={false}
              dataSource={formDefinition.table}
              columns={tableColumns}
              defaultExpandAllRows={true}
              rowClassName={(record) => (record.key === 'summary' ? 'summary-row' : record.children && 'group-row')}
              indentSize={0}
              scroll={{ y: 400 }}
            />
          )}
        </div>
        <div className="supervision-form-grid col-2">
          <div className="form-item">
            <div className="supervision-input-label">{strings.goalTillNextSupervision}</div>
            <textarea
              value={supervisionFormData.targetToNextSupervision}
              onChange={fieldChangeHandler('targetToNextSupervision')}
              readOnly={!canEdit}
            />
          </div>
          <div className="form-item">
            <div className="supervision-input-label">{strings.spokeScoreOfLesson}</div>
            <textarea
              value={supervisionFormData.textEvaluation}
              onChange={fieldChangeHandler('textEvaluation')}
              readOnly={!canEdit}
            />
          </div>
        </div>
      </div>
      <div className="supervision-footer">
        {canEdit && (
          <Button disabled={checkRequiredFields()} onClick={saveSupervision}>
            {supervisionFormData.status === 'done' ? strings.save : strings.evaluate}
          </Button>
        )}
      </div>
    </div>
  );
}

export default SupervisionForm;
