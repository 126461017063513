import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { push } from 'connected-react-router';
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Input, Table, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import '../../../index.scss';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
// Local imports
import { getAdminClientsAction, saveClient } from '../../../actions/admin.actions.js';
import { strings } from '../../../strings/StringsProvider';
import { getUsersAction } from '../../../actions/users.actions';
import './AdminClients.scss';
import PersonCard from '../../../components/PersonCard';
import Base64Image from '../../../components/Base64Image';
import { getDateDiff } from 'tools/date.tools';
import { sortNames, emptySorter } from 'tools/utils';
import { activeOptionMap } from 'data/enums';
import SearchBar from 'components/SearchBar';
import { useAppConfig } from 'context/AppGlobalContext';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

function AdminClients() {
  const sortOrders = useRef({ lastName: '', birthDate: '', age: '', region: '', city: '' });
  const firstMentorLoad = useRef(true);
  const firstAdminLoad = useRef(true);

  const history = useHistory();
  const searchParams = useQuery();
  const timerRef = useRef();

  const { isMobile } = useAppConfig();

  const { firstName, lastName, page, omama, active } = Object.fromEntries(searchParams.entries());

  const isMentor = jwtDecode(localStorage.getItem('access-token')).role === 'mentor';

  const dispatch = useDispatch();

  const totalClients = useSelector((state) => state.admin.totalClients);
  const { clientsTableData, usersById, mentorOmamas, sortedOmamas } = useSelector((state) => {
    const clients = state.admin.clients;
    const usersById = state.users.byId || {};
    const clientsTableData = clients.map((client) => ({
      ...client,
      tableBirthDate: dayjs(client.birthDate).format('D. M. YYYY'),
      omamaName: usersById[client.omamaID] && usersById[client.omamaID].name,
      activeForTable: client.active
        ? client.gender === strings.woman
          ? strings.activeUpperWoman
          : strings.activeUpperMan
        : client.gender === strings.woman
          ? strings.nonActiveUpperWoman
          : strings.nonActiveUpperMan,
    }));

    const mentorOmamas = [];
    if (isMentor) {
      for (const user of state.users.byArr) {
        mentorOmamas.push(user._id);
      }
    }

    const omamas = Object.values(usersById).filter((user) => user.role === 'omama');
    const sortedOmamas = sortNames(omamas);

    return {
      clientsTableData,
      usersById,
      mentorOmamas,
      omamas,
      sortedOmamas,
    };
  }, shallowEqual);

  // First loading by mentor
  if (firstMentorLoad.current && isMentor && mentorOmamas && mentorOmamas.length > 0) {
    if (!searchParams.get('omama')) {
      searchParams.set('omama', mentorOmamas);
    }
    const query = searchParams.toString();
    dispatch(getAdminClientsAction(query));
    firstMentorLoad.current = false;
  }

  // First loading by admin
  if (firstAdminLoad.current && !isMentor) {
    const query = searchParams.toString();
    dispatch(getAdminClientsAction(query));
    firstAdminLoad.current = false;
  }

  const updateClients = useCallback(() => {
    const query = searchParams.toString();
    dispatch(getAdminClientsAction(query));
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (Object.values(usersById).length === 0) {
      dispatch(getUsersAction());
    }
  }, [dispatch, usersById]);

  const userRole = jwtDecode(localStorage.getItem('access-token')).role;
  const changePage = (location) => dispatch(push(location));

  const setSearchParam = (paramName, paramValue) => {
    searchParams.set(paramName, paramValue);
    if (paramName !== 'page') {
      searchParams.set('page', 1);
    }
    history.replace(`?${searchParams.toString()}`);
    // debounce firstName and lastName
    if (['firstName', 'lastName'].includes(paramName)) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        updateClients();
      }, 1500);
    } else {
      updateClients();
    }
  };

  const deleteSearchParam = (paramName) => {
    searchParams.delete(paramName);
    searchParams.set('page', 1);
    history.replace(`?${searchParams.toString()}`);
    updateClients();
  };

  const changeSearchState = (name) => (event) => {
    switch (name) {
      case 'firstNameSearch':
        setSearchParam('firstName', event.target.value);
        break;
      case 'lastNameSearch':
        setSearchParam('lastName', event.target.value);
        break;
      default:
        break;
    }
  };

  const onOmamaChange = ({ key }) => {
    if (key === 'all') {
      if (isMentor) {
        setSearchParam('omama', mentorOmamas);
      } else {
        deleteSearchParam('omama');
      }
    } else {
      setSearchParam('omama', key);
    }
  };

  const omamaOptions = [
    {
      key: 'all',
      label: strings.allOmamas,
    },
    ...sortedOmamas.map((omama) => ({
      key: omama._id,
      label: omama.name,
    })),
  ];

  const onStatusChange = ({ key }) => {
    switch (key) {
      case 'all':
        deleteSearchParam('active');
        break;
      case strings.active:
        setSearchParam('active', true);
        break;
      case strings.notActiveSingular:
        setSearchParam('active', false);
        break;
      default:
        break;
    }
  };

  const statusOptions = [
    {
      key: 'all',
      label: strings.all2,
    },
    {
      key: strings.active,
      label: strings.activeUpper,
    },
    {
      key: strings.notActiveSingular,
      label: strings.nonActiveUpper,
    },
  ];

  const clearOtherSorts = (notToClear) => {
    Object.keys(sortOrders.current).forEach((column) => {
      if (column !== notToClear) {
        sortOrders.current[column] = '';
      }
    });
  };

  const getSortOrder = (column, currentSortOrder) => {
    if (column === 'age') {
      switch (currentSortOrder) {
        case '':
          return 'desc';
        case 'desc':
          return 'asc';
        case 'asc':
          return '';
        default:
          return '';
      }
    } else {
      switch (currentSortOrder) {
        case '':
          return 'asc';
        case 'asc':
          return 'desc';
        case 'desc':
          return '';
        default:
          return '';
      }
    }
  };

  const sortColumn = (column, sortOrder) => {
    if (sortOrder === '') {
      searchParams.delete('sortBy');
      searchParams.delete('sortOrder');
      searchParams.set('page', 1);
      history.replace(`?${searchParams.toString()}`);
      updateClients();
    } else {
      searchParams.set('sortBy', column);
      searchParams.set('sortOrder', sortOrder);
      searchParams.set('page', 1);
      history.replace(`?${searchParams.toString()}`);
      updateClients();
    }
  };

  const sortHandler = (column) => {
    const sortOrder = getSortOrder(column, sortOrders.current[column]);
    clearOtherSorts('column');
    sortOrders.current[column] = sortOrder;
    const sortBy = column === 'age' ? 'birthDate' : column;
    sortColumn(sortBy, sortOrder);
  };

  const mobileColumn = [
    {
      title: '',
      key: 'client',
      render: (client) => (
        <PersonCard
          key={client._id}
          name={`${client.firstName} ${client.lastName}`}
          birthDate={client.birthDate}
          id={client._id}
          photo={client._id}
          changePage={() => {
            dispatch(saveClient(client));
            changePage('/admin/klienti/' + client._id);
          }}
        />
      ),
    },
  ];

  const columns = [
    {
      title: '',
      dataIndex: '_id',
      key: 'photo',
      render: (id) => (
        <Base64Image
          backUpImage="/images/cestavonLOGO_unofficial.png"
          className="tablePhoto"
          type="user"
          imageID={id}
        />
      ),
    },
    {
      title: strings.firstName,
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: strings.lastName,
      dataIndex: 'lastName',
      key: 'lastName',
      sortDirections: ['ascend', 'descend'],
      onHeaderCell: () => ({
        onClick: () => sortHandler('lastName'),
      }),
      sorter: emptySorter,
    },
    {
      title: strings.birthDate,
      dataIndex: 'birthDate',
      key: 'tableBirthDate',
      sortDirections: ['ascend', 'descend'],
      render: (date) => <span>{dayjs(date).format('D. M. YYYY')}</span>,
      onHeaderCell: () => ({
        onClick: () => sortHandler('birthDate'),
      }),
      sorter: emptySorter,
    },
    {
      title: strings.age,
      dataIndex: 'birthDate',
      key: 'birthDate',
      sortDirections: ['ascend', 'descend'],
      onHeaderCell: () => ({
        onClick: () => sortHandler('age'),
      }),
      sorter: emptySorter,
      render: (date) => {
        const { months } = getDateDiff(date, new Date());
        /* eslint-disable */
        switch (true) {
          case months === 1:
            return <span>{`${months} ${strings.oneMonth}`}</span>;
          case months === 0 || months >= 5:
            return <span>{`${months} ${strings.zeroOrFiveMonths}`}</span>;
          case months >= 2 || months <= 4:
            return <span>{`${months} ${strings.twoTillFourMonths}`}</span>;
          default:
            return <span />;
        }
        /* eslint-enable */
      },
    },
    {
      title: strings.region,
      dataIndex: 'region',
      key: 'region',
      sortDirections: ['ascend', 'descend'],
      onHeaderCell: () => ({
        onClick: () => sortHandler('region'),
      }),
      sorter: emptySorter,
    },
    {
      title: strings.city,
      dataIndex: 'city',
      key: 'city',
      sortDirections: ['ascend', 'descend'],
      onHeaderCell: () => ({
        onClick: () => sortHandler('city'),
      }),
      sorter: emptySorter,
    },
    {
      title: strings.omama,
      dataIndex: 'omamaName',
      key: 'omamaName',
    },
    {
      title: strings.state,
      dataIndex: 'activeForTable',
      key: 'active',
    },
  ];

  return (
    <div className="statsBackground">
      <div className="clientsBox mobileBox">
        <div className="activities-main mobileActivities">
          <div className="clients-table-controls-group-container only-desktop" data-test-id="clients-form">
            <div className="clients-table-controls-group" data-test-id="clients-form-searchs">
              <SearchBar
                placeholder={strings.firstName}
                className="clients-search-bar"
                value={firstName}
                onChange={changeSearchState('firstNameSearch')}
                dataTestId="clients-form-firstNameInput"
              />
              <SearchBar
                placeholder={strings.lastName}
                className="clients-search-bar"
                value={lastName}
                onChange={changeSearchState('lastNameSearch')}
                dataTestId="clients-form-lastNameInput"
              />
            </div>
            <div className="clients-table-controls-group" data-test-id="clients-form-buttons">
              <Dropdown
                menu={{ items: omamaOptions, onClick: onOmamaChange }}
                trigger={['click']}
                className="table-control-dropdown clients-table-dropdown"
              >
                <div>
                  <div className="dropdown-value">{usersById[omama] ? usersById[omama].name : strings.allOmamas}</div>
                  <div className="dropdown-icon-container">
                    <img src="/images/Icons/down-icon.svg" alt="Dropdown icon" />
                  </div>
                </div>
              </Dropdown>
              <Dropdown
                menu={{ items: statusOptions, onClick: onStatusChange }}
                trigger={['click']}
                className="table-control-dropdown clients-table-dropdown"
              >
                <div>
                  <div className="dropdown-value">{active ? activeOptionMap[active] : strings.all2}</div>
                  <div className="dropdown-icon-container">
                    <img src="/images/Icons/down-icon.svg" alt="Dropdown icon" />
                  </div>
                </div>
              </Dropdown>
            </div>
            {userRole === 'admin' && (
              <Button
                className="clients-table-control-button"
                onClick={() => changePage('/admin/klient/pridat')}
                data-test-id="clients-form-registerClient"
              >
                {strings.add}
              </Button>
            )}
          </div>

          <div className="activities-form mobileActivities-form only-mobile-flex" data-test-id="clients-form">
            <div className="activities-form mobileActivities-form-inputs" data-test-id="clients-form-searchs">
              <Input
                className="activities-input mobile"
                style={{ marginRight: '20px' }}
                placeholder={strings.firstName}
                value={firstName}
                onChange={changeSearchState('firstNameSearch')}
              />
              <Input
                className="activities-input mobile"
                style={{ marginRight: '20px' }}
                placeholder={strings.lastName}
                value={lastName}
                onChange={changeSearchState('lastNameSearch')}
              />
              {userRole === 'admin' && (
                <div className="addClientIcon" onClick={() => changePage('/admin/klient/pridat')}>
                  <img className="ikona pridatButton" src="/images/Icons/plus_icon.png" alt="ikona" />
                </div>
              )}
            </div>
            <div className="activities-form mobileActivities-form-inputs" data-test-id="clients-form-buttons-mobile">
              <div className="clientsOmamaDropdown mobileDropdown">
                <Dropdown menu={{ items: omamaOptions, onClick: onOmamaChange }} trigger={['click']}>
                  <div>
                    {usersById[omama] ? usersById[omama].name : strings.allOmamas}
                    <DownOutlined style={{ paddingLeft: '10px' }} />
                  </div>
                </Dropdown>
              </div>
              <div className="clientsOmamaDropdown mobileDropdown" style={{ marginLeft: '40px' }}>
                <Dropdown menu={{ items: statusOptions, onClick: onStatusChange }} trigger={['click']}>
                  <div>
                    {active ? activeOptionMap[active] : strings.all2}
                    <DownOutlined style={{ paddingLeft: '10px' }} />
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
          {!isMobile && (
            <Table
              className="new-table clientsDesktopView"
              rowKey="_id"
              dataSource={clientsTableData}
              columns={columns}
              pagination={{
                current: Number(page) || 1,
                onChange: (pageNumber) => setSearchParam('page', pageNumber),
                total: totalClients,
              }}
              onRow={(record) => ({
                onClick: () => {
                  dispatch(saveClient(record));
                  changePage('/admin/klienti/' + record._id);
                },
              })}
              data-test-id="clients-table-desktop"
            />
          )}
          {isMobile && (
            <Table
              className="table tableMobile clientsMobileView"
              rowKey="_id"
              dataSource={clientsTableData}
              columns={mobileColumn}
              pagination={{
                current: Number(page) || 1,
                onChange: (pageNumber) => setSearchParam('page', pageNumber),
                total: totalClients,
              }}
              size="small"
              data-test-id="clients-table-mobile"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminClients;
